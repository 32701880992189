export const Page1Data = {
    videoSrc: "video/90s2.mov"
}
//page2
export const Page2Data = [{
        company_name: "伊利股份SH"
    },
    {
        time: "1996年03月12日",
        jys: "深圳证券交易所",
        address: "内蒙古"
    },
    {
        src1: "../../assets/img/90s/pic2.png",
        src2: "../../assets/img/90s/pic.png",
        src3: "../../assets/img/90s/pic4.png"
    }
]
//page3
export const Page3Data = [{
        company_name: "伊利股份"
    },
    {
        name: "潘刚",
        position: "52岁/董事长"
    },
    {
        name: "李峰",
        position: "43岁/董事"
    },
    {
        name: "张俊平",
        position: "60岁/董事"
    },
    {
        name: "闫俊荣",
        position: "50岁/执行董事"
    },

]
//page4
export const Page4Data = [{
    company_name: "伊利股份",
    perIncome: "40.2",
    rank: "26",
    chart1Data: {
        x: ["2017/12", "2018/12", "2019/12", "2020/12", "2021/12"],
        y: [11.08, 12.8, 15.3, 15.7, 40.2]
    },
    chart2Data: [{
            name: "本科",
            value: 35.53
        },
        {
            name: "硕士",
            value: 2.38
        },
        {
            name: "博士",
            value: 0.09
        },
        {
            name: "大专",
            value: 35
        },
        {
            name: "大专以下",
            value: 27
        },
    ],
}, ]
//page5
export const Page5Data = [{
        company_name: "伊利股份",
        income: "1106",
        ratio: "14.15",
        allPositive: [{
                letter: "2017年报",
                frequency: 680.58,
                suffix: "头"
            },
            {
                letter: "2018年报",
                frequency: 795.53,
                suffix: "只"
            },
            {
                letter: "2019年报",
                frequency: 902.23,
                suffix: "只"
            },
            {
                letter: "2020年报",
                frequency: 968.86,
                suffix: "只"
            },
            {
                letter: "2021年报",
                frequency: 1105.95,
                suffix: "头"
            },
        ],
    },

]

//page6
export const Page6Data = [{
        company_name: "伊利股份",
        profit: "87.05",
        ratio: "22.98",
        market1: [{
                label: "2021年报",
                value: 87.05
            },
            {
                label: "2020年报",
                value: 70.78
            },
            {
                label: "2019年报",
                value: 69.34
            },
            {
                label: "2018年报",
                value: 64.4
            },
            {
                label: "2017年报",
                value: 60.01
            },
        ],
    },
]
//page7
export const Page7Data = [{
    company_name: "伊利股份",
    population2: [
        { name: "液体乳", value: 849.11 },
        { name: "奶粉及奶制品", value: 162.09 },
        { name: "冷饮产品系列", value: 71.61 },
        { name: "其他", value: 1.82 },
      ],
}
]

//page8
export const Page8Data = [{
    company_name: "伊利股份",
    market1: [
        { label: "香港中央结算有限公司", value: 14.35 },
        { label: "呼和浩特投资有限责任公司", value: 8.85 },
        { label: "潘刚", value: 4.71 },
        { label: "中国证券金融股份有限公司", value: 3.0 },
        {
          label: "中国银行股份有限公司-易方达蓝筹精选混合型证券投资基金",
          value: 1.74,
        },
        { label: "赵成霞", value: 1.52 },
        { label: "刘春海", value: 1.51 },
        {
          label: "交通银行股份有限公司-易方达上证50指数增强型证券投资基金",
          value: 1.02,
        },
        { label: "阿布达比投资局", value: 0.74 },
        {
          label: "中国工商银行股份有点公司-东方红睿玺三年定期开放灵活配置",
          value: 0.7,
        },
      ],
}
]

//page9
export const Page9Data = [{
    company_name: "伊利股份",
    profit: "42.95",
    ratio: "2.42",
    allPositive: [
        { letter: "2022/03", frequency: 471066 },
        { letter: "2021/12", frequency: 619662 },
        { letter: "2021/09", frequency: 565651},
        { letter: "2021/06", frequency: 440140 },
        { letter: "2021/03", frequency: 429470},
      ],
}
]

//page10
export const Page10Data = [{
    company_name: "伊利股份",
    chartData: {
        x:["2018", "2019", "2020", "2021", "2022"],
        y1:[10.66, 9.86, 5.58, 10.33, 11.3],
        y2:[-3.09, -7.5, -43.41, 85.13, 9.39],
        y3:[
            {
              yAxis: 60,
            },
            {
              yAxis: 100,
            },
            {
              yAxis: 150,
            },
            {
              yAxis: 200,
            },
            {
              yAxis: 300,
            },
          ],

    }
}
]
//page11
export const Page11Data = [{
    company_name: "伊利股份",
    chartData: {
        x:["2018", "2019", "2020", "2021", "2022"],
        y1:[10.66, 9.86, 5.58, 10.33, 11.3],
        y2:[-3.09, -7.5, -43.41, 85.13, 9.39],
        y3:[
            {
              yAxis: 60,
            },
            {
              yAxis: 100,
            },
            {
              yAxis: 150,
            },
            {
              yAxis: 200,
            },
            {
              yAxis: 300,
            },
          ],

    }
}
]
//page12
export const Page12Data = [{
    company_name: "伊利股份",
    chartData: {
        x:["2018", "2019", "2020", "2021", "2022"],
        y1:[10.66, 9.86, 5.58, 10.33, 11.3],
        y2:[-3.09, -7.5, -43.41, 85.13, 9.39],
        y3:[
            {
              yAxis: 60,
            },
            {
              yAxis: 100,
            },
            {
              yAxis: 150,
            },
            {
              yAxis: 200,
            },
            {
              yAxis: 300,
            },
          ],

    }
}
]

//page13
export const Page13Data = [{
    company_name: "伊利股份",
    secode:"伊利股份 600780",
    chartData:{
        text:"56",
    }

  
}
]
