<template>
  <!-- page1 -->
  <div class="width-100 height-100 content" id="card1">
    <audio :src="audiosrc" ref="audio" autoplay="autoplay"></audio>
    <video
      class="end_video"
      :src="src"
      muted
      width="100%"
      height="100%"
      ref="endVideo"
    ></video>
  </div>
</template>
<script>
import {Page1Data} from "./data.js"
export default {
  name: "OneComponent",
  data: function () {
    return {
      audiosrc: "",
      audioData: {},
      src:"",
    };
  },
  mounted(){
    this.src=Page1Data.videoSrc;
  },

  props: {
    tempAduioArr: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    tempAduioArr: {
      handler(val) {
        console.log(val);
        if (val.length > 0) {
          this.audioData = val[0];
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {},
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.audioData.audio_link;
      console.log('audiosrc',this.audiosrc );
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });
      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    async playPage1() {
      this.$refs.endVideo.play();
    },
    getTime() {
      let time = 4; 
      let audioTime = Math.ceil(this.audioData.audio_length);
      if (audioTime > time) {
        time = audioTime;
      }
      return {
        audio_link: this.audioData.audio_link,
        pageLen: time,
      };
    },
  },
};
</script>
<style scoped lang="less">
</style>

